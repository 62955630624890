import React from 'react';
import './App.css';
/*import pessoa from './image/pessoa.png';
import logo from './image/logo.png';*/
import { useMediaQuery } from 'react-responsive';
import { BigScreen } from './components/big-screen/big-screen.component';
import { Mobile } from './components/mobile/mobile.component';

function App() {

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 550px)",
  });

  /*const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 744px)",
  });

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1280px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1440px)",
  });*/

  const isBigScreen = useMediaQuery({
    query: "(min-device-width: 550px)",
  });

  return (
    <div>
      {isMobileDevice && <Mobile />}
      {isBigScreen && <BigScreen />}
    </div>
  );
}

export default App;
