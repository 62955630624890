import React from 'react'
import './mobile.styles.css'
import pessoa from '../../image/pessoa.png';
import logo from '../../image/logo_place.png';
import { emailsRef, analytics } from '../../firebase';
import { push } from "firebase/database";
import { setUserProperties } from "firebase/analytics";

export const Mobile = () => {
    return (
        <div style={{position:"absolute", bottom:0, width:"100%", background:"linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #A4C2B2 100%)"}}>
            <img src={pessoa} style={{position:"absolute", width:"40%", minWidth:"250px", height:"auto", bottom:0, right:0, objectFit:"contain", zIndex:-1}} alt="Pessoa"></img>           
        <div style={{display:"table-cell", verticalAlign:"middle", height:"712px"}}>
        <img src={logo} style={{marginLeft:"5%", width:"60%", minWidth:"250px", maxWidth:"450px", height:"auto", position:"absolute", top:"15%"}} alt="Logo"></img> 
        <p style={{fontSize:"9vw", fontFamily:"Castoro", marginLeft:"10%", width:"80%", marginBottom:"1%", marginTop:"70%", color:"#333333ff", lineHeight:1}}>Um novo jeito para você mudar o mundo</p>
        {/*<p style={{fontSize:"1.7vw", fontFamily:"Assistant", marginLeft:"10%", width:"60%", marginTop:0, color:"#333333ff", marginBottom:"5%"}}>Inscreva a sua empresa abaixo e descubra através do ESG.</p>*/}
        <form id="leadForm" onSubmit={submitForm} style={{display:"block"}}>
        <input type="text" id="email" name="email" style={{fontSize:"4.2vw", fontFamily:"Assistant", marginLeft:"10%", width:"60%", marginTop:0, borderRadius:4, border:"1px solid rgb(110 107 107)", paddingLeft:"10px", background:"transparent", borderTop:0, borderLeft:0, borderRight:0, borderEndEndRadius:"unset", borderEndStartRadius:"unset", outline:0}} className="input-email" placeholder="Digite o seu email corporativo e..." required/>
        <button id="descobrir" type="submit" style={{position:"relative", marginLeft: "30%", fontSize:"4.2vw", fontFamily:"Assistant", marginRight:"10%", minWidth:"10%", height:"3.4vw", minHeight:"30px", marginTop:"2%", borderRadius:4, backgroundColor:"#6C905B", color:"#FFFFFF", fontWeight:"600", transitionDuration:"0.4s", textDecoration:"none", border:"none", transition:"width .35s ease-in-out", cursor:"pointer"}} className="button-discovery">descubra</button>
        </form>
        <p id="message" className="message" style={{display:"none", fontSize:"4.2vw", marginLeft:"10%", marginRight:"10%", fontFamily:"Assistant"}}>Muito obrigado pelo seu cadastro, logo entraremos em contato.</p>
        </div>        
        </div>
    )
}
//document.getElementById("leadForm").addEventListener("submit", submitForm);

function submitForm(e) {
    e.preventDefault();

    var email = getInputVal('email');

    insertEmail(email);

    document.getElementById("leadForm").reset();
    document.querySelector('.input-email').style.display = "none";
    document.querySelector('.button-discovery').style.display = "none";
    document.querySelector('.message').style.display = "block";
}

function getInputVal(id) {
    return document.getElementById(id).value;
}

function insertEmail(email) {
    push(emailsRef, {
        email: email
    });
    
    setUserProperties(analytics, { emailSent: true });
}