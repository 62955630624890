import { initializeApp } from "firebase/app";
import { getDatabase, ref } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCAyMcL6nO115gp0AhP2c-juEqsQG9KWSk",
  authDomain: "igreen-c27f1.firebaseapp.com",
  projectId: "igreen-c27f1",
  storageBucket: "igreen-c27f1.appspot.com",
  messagingSenderId: "246072644435",
  appId: "1:246072644435:web:7e47d5e7ff29875b8807a6",
  measurementId: "G-Z9J0SMTGLE"
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
export const emailsRef = ref(db, "emails");
export const analytics = getAnalytics(app);